import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'theme';

import {Landing} from 'pages';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
          <Switch>
            <Route path="/">
              <Landing />
            </Route>
          </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;