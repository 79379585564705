import React, { useState, useEffect, FormEvent, useRef } from 'react';
import firebase from 'config/firebase';
import { auth } from 'firebase';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'


import styles from './landing.module.css';
import {LandingPageBackground} from 'assets/images/';

import IMG_BLOB from 'assets/images/blob.svg';
import IMG_LOGO from 'assets/images/club-logo.svg';
import IMG_APPLE from 'assets/images/apple.png';
import IMG_GOOGLE from 'assets/images/google.png';


interface Errors {
  email: null | string;
  password: null | string;
  general: null | string;
}

const _initialErrorState: Errors = {
  email: null,
  password: null,
  general: null,
};

const Landing: React.FC = () => {
  const [_password, _setPassword] = useState('');
  const [_email, _setEmail] = useState('');
  const [_isLoading, _setIsLoading] = useState(false);
  const [_userForgotPassword, _setUserForgotPassword] = useState(false);
  const [_errors, _setErrors] = useState<Errors>(_initialErrorState);
  const [_successMessage, _setSuccessMessage] = useState<string>('');
  const [_cachedUser, _setCachedUser] = useState<boolean>(false);
  const [_showNonSubcriptionModal, _setShowNonSubcriptionModal] = useState<boolean>(false);

  const _userHasAccess = useRef<boolean>(false);
  const _userHasSubmitted = useRef<boolean>(false);
  
  useEffect(() => {
    const cleanUp = firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      _setIsLoading(false);
            
      if (user && _userHasAccess.current) {
        _redirectToOAuth(user)
      } else if (user && !_userHasSubmitted.current) {
        //Show alert to switch accounts
        _setCachedUser(true);
      }
    });

    return () => {
      cleanUp();
    };
  }, [_userHasAccess.current]);


  async function _redirectToOAuth(
    user: firebase.User | null,
    url: string = process.env.REACT_APP_OAUTH_URL as string
  ) {
    _setIsLoading(true);

    if (!user) {
      _handleFailedRedirect(new Error('Please make sure you logged in with an existing Ferly account'));
      return;
    }
    
    try {
      
      // todo -> check if has active subscription
      const userHasSubscriptionResponse = await firebase.functions().httpsCallable('userHasSubscription')();

      if (userHasSubscriptionResponse.data === false) {
        return _handleNonSubscriptionUser();
      }

      const authToken = new URLSearchParams(window.location.search).get(
        "authToken"
      );
    
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          auth_token: authToken,
          id_token: await user.getIdToken(),
          success: "true",
        }),
      });
      
      const data = await response.json();
      window.location = data.url;
      
    } catch(e) {
      _handleFailedLogin(e);
    }
  }

  const _onModalClose = async ():Promise<void> => {
    _setIsLoading(true);
    _setCachedUser(false);
    await auth().signOut();
    _setIsLoading(false);
    
  }

  const _onModalConfirm = ():void => {
    _setCachedUser(false);
    const _currentUser = auth().currentUser;
    _redirectToOAuth(_currentUser);
  }

  const _nonSubscriptionModalConfirm = (): void => {
    _setShowNonSubcriptionModal(false);
  }

  // LOGINS
  const _startGoogleSignin = (): void => {
    _setIsLoading(true);

    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(_handleLoginResult)
      .catch(_handleFailedLogin);
  };
  
  const _startAppleSignin = (): void => {
    _setIsLoading(true);

    const provider = new firebase.auth.OAuthProvider('apple.com');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(_handleLoginResult)
      .catch(_handleFailedLogin);
  };
  
  const _startEmailLogin = (): void => {
    _setIsLoading(true);

    firebase.auth().signInWithEmailAndPassword(_email, _password)
    .then(_handleLoginResult)
    .catch(_handleFailedLogin);
  }

  const _handleLoginResult = (result: firebase.auth.UserCredential): void => {
    _userHasSubmitted.current = true;
    if (!result?.additionalUserInfo?.isNewUser) {
      _userHasAccess.current = true;
    } else {
      _handleNewUser();
    }
}
  const _handleNewUser = (): void => {
    _handleFailedRedirect(new Error('We were unable to log you in. Please make sure you log in with an existing account.'));
    firebase.auth().currentUser?.delete(); // TODO
  }

  // FORM
  const _onFormSubmit = (event:FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    _startEmailLogin();
  }

  const _handleFailedRedirect = (error: Error) => {
    _setIsLoading(false);
    
    _setErrors({
      ..._initialErrorState,
      general:  error?.message || 'Something went wrong. Please try again later',
    })
  }

  const _onEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _setEmail(event.target.value)
    _setErrors(_initialErrorState)
  }
  
  const _onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    _setPassword(event.target.value)
    _setErrors(_initialErrorState)
  }

  const _handleFailedLogin = (error: firebase.FirebaseError): void => {
    _setIsLoading(false);
    
    
    const _newErrorState = {
      ..._initialErrorState
    }

    console.log(error.code);
    
    
    switch (error.code) {
      case 'auth/user-not-found':
        _newErrorState.email = "We couldn't a user with this email address"
        break;
      
      case 'auth/wrong-password':
      _newErrorState.password = "Wrong password! Please try again."
      break;
      
      case 'auth/popup-closed-by-user':
        break;
    
      default:
        _newErrorState.general = error.message;
        break;
    }

    _setErrors(_newErrorState);
    
  }

  const _handleNonSubscriptionUser = async (): Promise<void> => {
    _setCachedUser(false);
    await auth().signOut();
    _setIsLoading(false);
    _setShowNonSubcriptionModal(true);
  }

  const _onForgotPasswordClick = () => {
    _setUserForgotPassword(true);
  }

  const _onForgotPasswordCancel = () => {
    _setUserForgotPassword(false);
  }

  const _onForgotPasswordSubmit = async () => {
    try {
      await auth().sendPasswordResetEmail(_email);

      _setSuccessMessage('An email with instructions has been sent to ' + _email + '.');
      _setUserForgotPassword(false);
    } catch (error) {

      _setErrors({
        ..._initialErrorState,
        general:  error?.message || 'Something went wrong. Please try again later',
      })
      _setUserForgotPassword(false);
    }
  }

  const _branchUrl = 'https://ferly.app/join-the-club';
  

  return (
    <div className={styles.mainContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.loginSection}>
          <h1 className={styles.title}>
            <img src={IMG_LOGO} alt="Ferly Club"/>
          </h1>
          <div className={styles.intro}>
            {"Log in with your Ferly account and join the club!"}
          </div>
          <div className={styles.socialLoginButtons}>
              <Button color="secondary" type="button" variant="outlined" onClick={_startGoogleSignin} className={styles.socialLoginButton}>
                <img src={IMG_GOOGLE} alt="Google"/>
                {"Sign in with Google"}
              </Button>
              <Button color="secondary" type="button" variant="outlined" onClick={_startAppleSignin} className={styles.socialLoginButton}>
                <img src={IMG_APPLE} alt="Apple"/>
                {"Sign in with Apple"}
              </Button>
          </div>
          <form className={styles.form} id="firebaseui-auth-container" onSubmit={_onFormSubmit}>
            <div className={styles.formControl}>
              <TextField
                className={styles.formInput}
                label="Email address"
                required
                type="email"
                name="email"
                value={_email}
                onChange={_onEmailInputChange}
                error={_errors.email !== null}
                helperText={_errors.email}
                />
            </div>
            <div className={styles.formControl}>
              <TextField
                className={styles.formInput}
                label="Password"
                required
                name="password"
                type="password"
                value={_password}
                onChange={_onPasswordInputChange}
                error={_errors.password !== null}
                helperText={_errors.password}
              />
            </div>
            {_errors.general && (
                <FormHelperText className={styles.generalError} error={true}>{_errors.general}</FormHelperText>
                )}
            {_successMessage && (
              <FormHelperText className={styles.successMessage}>{_successMessage}</FormHelperText>
            )}
            <div className={styles.formButtonWrapper}>
              <Button color="primary" type="submit" variant="contained" onClick={_startEmailLogin} className={styles.formButton}>
                {"Log in"}
              </Button>
              <div className={styles.formButtonLink}>
                <Button color="secondary" size="small" onClick={_onForgotPasswordClick}>
                  {'Forgot your password?'}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

        <div className={styles.downloadApp}>
          <div className={styles.downloadTitle}>{"Don't have an account?"}</div>
          <div className={styles.downloadText}>
            {"No problem! Download our app and start your journey towards a healthy, confident & pleasurable sex life."}
          </div>
          <Button size="small" variant="outlined" className={styles.btnDownload} href={_branchUrl} target="_blank">
            {"Download the Ferly app"}
          </Button>
        </div>
        <img src={LandingPageBackground} className={styles.collage} />
        <img src={IMG_BLOB} alt="Blob" className={styles.blobImage} />

      
      <Backdrop open={_isLoading} classes={{root: styles.backdrop}}>
				<CircularProgress color="secondary" />
			</Backdrop>

      {/* Switch accounts */}
      <Dialog
        open={_cachedUser}
        onClose={_onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"It looks like you already logged in before!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'You previously logged in '}
            {auth().currentUser?.email ? 
              'as ' + auth().currentUser?.email :
              'with anouther account'}
              {'. Would you like to continue with this account?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={_onModalClose} color="secondary">
            Change accounts
          </Button>
          <Button onClick={_onModalConfirm} color="primary" autoFocus variant="outlined">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Free user */}
      <Dialog
        open={_showNonSubcriptionModal}
        onClose={_nonSubscriptionModalConfirm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"We're sorry, this is Ferly premium members only!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {'Download the Ferly app and get a subscription.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" href={_branchUrl} target="_blank">
            {"Download the Ferly app"}
          </Button>
          <Button onClick={_nonSubscriptionModalConfirm} color="primary" autoFocus variant="outlined">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Forgot Password */}
      <Dialog
        open={_userForgotPassword}
        onClose={_onForgotPasswordCancel}
        aria-labelledby="password-dialog-title"
        aria-describedby="password-dialog-description"
      >
        <DialogTitle id="password-dialog-title">{"Reset your password."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="password-dialog-description">
            {"Enter the email address associated with your account and we'll send an email with instructions to reset your password"}
          </DialogContentText>
            <div className={styles.dialogForm}>

            <div className={styles.formControl}>
              <TextField
                className={styles.formInput}
                label="Email address"
                required
                type="email"
                name="email"
                value={_email}
                onChange={_onEmailInputChange}
                error={_errors.email !== null}
                helperText={_errors.email}
                />
            </div>
            </div>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={_onForgotPasswordCancel}>
            {"Cancel"}
          </Button>
          <Button onClick={_onForgotPasswordSubmit} color="primary" variant="outlined">
            Reset password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Landing; 