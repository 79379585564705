import {unstable_createMuiStrictModeTheme as createMuiTheme} from '@material-ui/core/styles';

const _font =  "'Nunito Sans', sans-serif";
const theme = createMuiTheme({
  typography: {
    fontFamily: _font,
    button: {
      textTransform: 'none',
    }
  },
  palette: {
    primary: {
      light: '#D9DCFF', //light pink
      main: '#6369bd', // purple
      dark: '#3A3E7C', //dark green
      contrastText: '#ffffff', // whitee
    },
    secondary: {
      light: '#FF8276', //light blue
      main: '#363D2B', // dark green
      dark: '#FFEAE8', // light light red
      contrastText: '#D0E7AD', // green
    },
    action: {
      active: '#fff',
    }
  },
});
export default theme;
